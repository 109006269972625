.AllProjects{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    width: 100%;
    height: 90vh;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 5vh;
    background-color: black;
    text-align: center;
    color: white;
    box-sizing: border-box;
    overflow-y: scroll;

}

.SingleProject {
    margin-top: 2vh;
}
