.footer {
    background-color: black;
    color: white;
    text-align: center;
    padding-top: 1vh;
    font-weight: bold;
    font-style: italic;
    font-size: 0.8em;
    height: 5vh;
    box-sizing: border-box;
}

.footer span {
    padding-right: 1vw;
}
