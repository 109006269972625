.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    width: 100%;
    height: 90vh;
    padding-top: 10vh;
    background-color: black;
    box-sizing: border-box;
}

.Container {
    text-align: center;
    color: white;
}

.Nom {
    font-style: italic;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "Yellowtail";
}

.Prenom {
    font-style: italic;
    font-size: 1.5rem;
    font-family: "Yellowtail";
}

.Title {
    font-size: 1.5rem;
}

.DownloadButton {
    padding-top: 10vh;
}

.LibelleButton {
    font-size: x-large;
}

.LibelleButton:after {
    content: "";
    background: url('../../assets/images/emblemdownloads_93482.png') ;
    background-size:cover;
    width: 25px;
    height: 25px;
    display: inline-block;
    position: relative;
    left: 15px;
    top:5px;
}

@media screen and (min-width: 650px) {
    .Names {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .Nom {
        padding-left: 10px;
    }
    .DownloadButton {
        padding-top: 15vh;
    }

}
