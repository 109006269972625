.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    width:100%;
    padding-left: 10%;
}

.NavigationItem a {
    color: black;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: white;
    background-color: rgb(208, 197, 197);
}

.Icon {
    display:flex;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 15px;
}

.Home {
    background-image: url(../../assets/images/home.png);
}

.Skills {
    background-image: url(../../assets/images/skill.png);
}

.Contact {
    background-image: url(../../assets/images/contact.png);
}

.Projects {
    background-image: url(../../assets/images/projects.png);
}

.Langues {
    background-image: url(../../assets/images/language.png);
}



@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height:100%;
        width: auto;
        align-items: center;
        padding-left: 0px;
    }

    .NavigationItem a {
        color: white;
        height: 100%;
        padding: 4vh 10px;
        border-bottom: 4px solid transparent;
        background-color: black;

    }

    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        background-color: white;
        border-bottom: 4px solid black;
        color: black;
    }

    .Icon {
        display:none;
    }
}
