.Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    width: 100%;
    height: 90vh;
    padding-top: 10vh;
    background-color: black;
    color: white;
    box-sizing: border-box;
    padding: 5vw;
    padding-top: 15vh;
}

.Identite {
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
}

.Identite input {
    border-radius: 25px;
    border: solid 1px #ccc;
    padding: 6px 10px;
    width: 75vw;
    height: 30px;
    margin-top: 2vh;
    box-sizing: border-box;
}

.Identite input:focus, .Message textarea:focus {
    outline: none;
    background-color: #ccc;
}


.Message textarea {
    border-radius: 25px;
    padding: 6px 10px;
    width: 75vw;
    box-sizing: border-box;
}

.Button {
    padding-top: 2vh;
    display: flex;
    justify-content: center;
}
