@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Yellowtail&display=swap');

html {
  height: 100%;
  font-size: 16px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Lato", "Yellowtail", cursive, sans-serif;

}

div#root {
  height: 100%;
}
