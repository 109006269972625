.Button {
    background-color: transparent;
    display: inline-block;
    outline: none;
    color: white;
    cursor: pointer;
    font: inherit;
    font-size: 1rem;
    padding: 5px 20px;
    margin: 1vh 3vw;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border-radius: 0.25rem;
}

.Normal {
    border: none;
    background-color: #ccc;
}

.Bordered {
    border: 2px solid black;
}

.BlackText {
    color: black;
}

.Button:disabled{
    color: #ccc;
    background-color: #ddd;
    border: 2px solid #ccc;
    cursor: not-allowed;
}

.Success {
    background-color: chartreuse;
}

.Outline-Success {
    color: chartreuse;
    border: 2px solid chartreuse;
    background-color: transparent;
}

.Selection {
    background-color: rgb(58, 121, 135);
}

.Outline-Selection {
    color: rgb(58, 121, 135);
    border: 2px solid rgb(58, 121, 135);
    background-color: transparent;
}

.Danger {
    background-color: #fa1010;
}

.Outline-Danger {
    color: #fa1010;
    border: 2px solid #fa1010;
    background-color: transparent;
}

.Primary {
    background-color: #04a8f3;
}

.Outline-Primary {
    color: #04a8f3;
    border: 2px solid #04a8f3;
    background-color: transparent;
}

.Warning {
    background-color: #ffca2c;
}

.Outline-Warning {
    color: #ffca2c;
    border: 2px solid #ffca2c;
    background-color: transparent;
}

.Info {
    background-color: #ac9454;
}

.Outline-Info {
    color: #ac9454;
    border: 2px solid #ac9454;
    background-color: transparent;
}

.Button:hover {
    color: black;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.BlackText:hover {
    color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.Button:active {
    box-shadow: none;
    transform: translateY(0);
}

.Button:focus {
    border: 5px solid black;
}

.Outline-Success:hover {
    color: white;
    background-color: chartreuse;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.Outline-Success:active {
    box-shadow: none;
    transform: translateY(0);
}

.Outline-Success:focus {
    border: 5px solid chartreuse;
}

.Outline-Selection:hover {
    color: white;
    background-color: rgb(58, 121, 135);
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.Outline-Selection:active {
    box-shadow: none;
    transform: translateY(0);
}

.Outline-Selection:focus {
    border: 5px solid rgb(58, 121, 135);
}

.Outline-Danger:hover {
    color: white;
    background-color: #fa1010;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.Outline-Danger:active {
    box-shadow: none;
    transform: translateY(0);
}

.Outline-Danger:focus {
    border: 5px solid #fa1010;
}

.Outline-Primary:hover {
    color: white;
    background-color: #04a8f3;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.Outline-Primary:active {
    box-shadow: none;
    transform: translateY(0);
}

.Outline-Primary:focus {
    border: 5px solid #04a8f3;
}

.Outline-Warning:hover {
    color: white;
    background-color: #ffca2c;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.Outline-Warning:active {
    box-shadow: none;
    transform: translateY(0);
}

.Outline-Warning:focus {
    border: 5px solid #ffca2c;
}

.Outline-Info:hover {
    color: white;
    background-color: #ac9454;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.Outline-Info:active {
    box-shadow: none;
    transform: translateY(0);
}

.Outline-Info:focus {
    border: 5px solid #ac9454;
}
