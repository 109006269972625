.ProjetDetail{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    width: 100%;
    height: 90vh;
    padding-top: 5vh;
    background-color: black;
    text-align: center;
    color: white;
    box-sizing: border-box;
    overflow-y: scroll;

}

.Project {
    border: 2px solid green;
    min-width: 90vw;
    max-width: 90vw;
    margin-top: 3vw;
    border-radius: 22px;
    padding: 15px 5px;
    box-shadow: 6px 6px 0px rgb(15, 221, 101);
}

.Retour {
    padding-top: 2vh;
}

.Retour a {
    color: brown;
    font-size: 1.5rem;
    font-weight: 400;
}


.ProjectName {
    font-style: italic;
    font-size: 1.7rem;
    font-weight: 900;
    padding-bottom: 2vh;
}

.ProjectImage {
    padding-bottom: 2vh;
}

.ProjectImage img {
    width: 80vw;
    height:20vh
}

.BackendName {
    margin-bottom: 1vh;
    font-weight: 700;
    font-size: 1.5rem;
}

.FrontendName {
    margin-bottom: 1vh;
    margin-top: 1vh;
    font-weight: 700;
    font-size: 1.5rem;
}

.DescriptionName {
    margin-bottom: 1vh;
    margin-top: 1vh;
    font-weight: 700;
    font-size: 1.5rem;
}

.DescriptionValue {
    padding: 0 1vh 2vh 1vh;
}

.Url a {
    color: rgb(15, 221, 101);
}

@media screen and (min-width: 800px) {
    .ProjectImage img {
        width: 50vw;
        height:30vh
    }
    .Backend {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .Frontend {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .Description {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;
    }
    .BackendName {
        flex: 1 0 40%;
        text-align: end;
        padding-right: 3vw;
    }
    .BackendValue {
        flex: 1 0 60%;
        text-align: start;

    }
    .FrontendName {
        flex: 1 0 40%;
        text-align: end;
        padding-right: 3vw;
    }
    .FrontendValue {
        flex: 1 0 60%;
        text-align: start;

    }
    .DescriptionName {
        flex: 1 0 40%;
        text-align: end;
        padding-right: 3vw;
    }
    .DescriptionValue {
        flex: 1 0 60%;
        text-align: start;
        padding-right: 3vw;

    }

  }
