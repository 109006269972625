.Project {
    border: 2px solid green;
    min-width: 90vw;
    max-width: 90vw;
    min-height: 20vh;
    margin-top: 3vw;
    border-radius: 22px;
    padding: 15px 5px;
    box-shadow: 6px 6px 0px rgb(15, 221, 101);
}

.ProjectName {
    font-style: italic;
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 5vw;
}

.ProjectImage {
    padding-bottom: 5vw;
}

.ProjectImage img {
    width: 80vw;
}

.ProjectButton a {
    color: green;
    font-size: 1.5rem;
    font-weight: 400;
}

@media screen and (min-width: 800px) {
    .ProjectImage img {
        width: 40vw;
    }
    .Project {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 1vh;
        padding: 20px 5px;
    }
    .ProjectName {
        flex: 1 0 50%;
    }
    .ProjectImage {
        flex: 1 0 50%;
    }
    .ProjectButton {
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }

  }
