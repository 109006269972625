.Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    width: 100%;
    height: 90vh;
    padding-top: 5vh;
    background-color: black;
    text-align: center;
    color: white;
    overflow:hidden;
    box-sizing: border-box;
}

.Title {
    font-style: italic;
    font-size: 1.5rem;
    font-weight: 700;
}

.AllLangues {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SingleLangue {
    display: flex;
    flex-direction: column;
    margin-bottom: 2vh;
    width:90vw;
    align-items: center;
}

.NomLangue {
    font-weight: 900;
    font-size: 1.7rem;
    width: 45vw;
    text-align: center;
    box-sizing: border-box;
}

.FluencyLangue {
    text-align: start;
}

@media screen and (min-width: 400px) {
    .NomLangue {
        text-align: right;
        padding-left: 10vw;
    }
    .SingleLangue {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 2vh;
    }
    .FluencyLangue {
        padding-left: 3vw;
    }


  }
