.Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    height: 90vh;
    padding-top: 10vh;
    background-color: black;
    color: white;
    box-sizing: border-box;
    padding: 10vw;
}
