.Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    width: 100%;
    height: 90vh;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 5vh;
    background-color: black;
    text-align: center;
    color: white;
    overflow:hidden;
    box-sizing: border-box;
}


.Title {
    font-style: italic;
    font-size: 1.5rem;
    font-weight: 700;
}

.AllSkills {
    padding-left: 50px;
    padding-right: 10px;
    overflow-y: scroll;
}

.Domaine {
    margin-bottom: 5vh;
}

.DomaineName {
    margin-bottom: 2vh;
    font-weight: 900;
    font-size: 1.7rem;
}

.Skills {
    padding: 0px 10px;
}

@media screen and (min-width: 650px) {
    .Domaine {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }
    .DomaineName {
        width: 35vh;
        text-align: left;
    }
    .Skills {
        text-align: left;
        flex:1;
    }
    .AllSkills {
        padding-left: 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
