.header {
    background-color: black;
    text-align: center;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    display: flex;
    flex-direction: row-reverse;
    box-sizing: border-box;
    height: 5vh;
}
